<template>
  <div class="st-copy" :class="classes" @click="handleCopy(props.value)">
    <div class="clipboard">
      <slot>{{ props.value }}</slot>
    </div>
    <StTooltip trigger="hover" placement="top">
      <template #activator>
        <StIcon
          data-t="activator"
          class="icon-activator"
          :name="isCopyed ? 'check' : 'copy-solid'"
          :size="iconSize"
        />
      </template>
      <span class="tooltip" data-t="clipboard-tooltip">
        {{ tooltipMesage }}
      </span>
    </StTooltip>
  </div>
</template>

<script setup lang="ts">
import { delay } from '@st/utils'

interface Props {
  value: string
  iconSize?: number
  type?: 'default' | 'primary'
  tooltip?: string
  successTooltip?: string
}

const { t } = useI18n()

const props = withDefaults(defineProps<Props>(), {
  iconSize: 16,
  type: 'default',
  tooltip: '',
  successTooltip: '',
})

const { copy } = useClipboard()

const isCopyed = ref(false)
async function handleCopy(value: string) {
  copy(value)
  isCopyed.value = true
  await delay(3000)
  isCopyed.value = false
}
const tooltipMesage = computed(() =>
  isCopyed.value
    ? props.successTooltip || t('coupon.copySuccess')
    : props.tooltip || t('coupon.copy'),
)

const classes = computed(() => [props.type])
</script>

<style scoped>
.icon-activator {
  cursor: pointer;
  color: var(--text-tertiary);
}

.st-copy {
  cursor: pointer;
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  &:hover {
    color: var(--text-primary);

    .icon-activator {
      color: var(--text-primary);
    }
  }

  &.primary {
    .icon-activator {
      color: var(--icon-brand);
    }

    &:hover {
      .icon-activator {
        color: #dd6a28; /* stylelint-disable-line */
      }
    }
  }
}

.clipboard {
  overflow: hidden;
  display: flex;
  align-items: center;

  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltip {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-100) var(--spacing-150);

  font: var(--desktop-text-xs-medium);
  color: var(--text-primary);
}
</style>
